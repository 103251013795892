[data-pnotify].material-elem, [data-pnotify].material-elem.pnotify-mode-light {
  --notice-background-color: #fff59d;
  --notice-text-color: #000;
  --info-background-color: #9dfff5;
  --info-text-color: #000;
  --success-background-color: #a7ff9d;
  --success-text-color: #000;
  --error-background-color: #ffc49d;
  --error-text-color: #000;
  --primary-button-text-color: #3f51b5;
  --primary-button-text-focus-color: #303f9f;
  --input-focus-underline-color: #3f51b5;
  border-radius: 0;
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  [data-pnotify].material-elem.pnotify-mode-no-preference {
    --notice-background-color: #ff8f00;
    --notice-text-color: #fff;
    --info-background-color: #006eff;
    --info-text-color: #fff;
    --success-background-color: #00c000;
    --success-text-color: #fff;
    --error-background-color: #f10;
    --error-text-color: #fff;
    --primary-button-text-color: #e1f5fe;
    --primary-button-text-focus-color: #e0f7fa;
    --input-focus-underline-color: #e1f5fe;
  }
}

[data-pnotify].material-elem.pnotify-mode-dark {
  --notice-background-color: #ff8f00;
  --notice-text-color: #fff;
  --info-background-color: #006eff;
  --info-text-color: #fff;
  --success-background-color: #00c000;
  --success-text-color: #fff;
  --error-background-color: #f10;
  --error-text-color: #fff;
  --primary-button-text-color: #e1f5fe;
  --primary-button-text-focus-color: #e0f7fa;
  --input-focus-underline-color: #e1f5fe;
}

[data-pnotify] .material-notice {
  --material-background-color: var(--notice-background-color);
  --material-text-color: var(--notice-text-color);
}

[data-pnotify] .material-info {
  --material-background-color: var(--info-background-color);
  --material-text-color: var(--info-text-color);
}

[data-pnotify] .material-success {
  --material-background-color: var(--success-background-color);
  --material-text-color: var(--success-text-color);
}

[data-pnotify] .material-error {
  --material-background-color: var(--error-background-color);
  --material-text-color: var(--error-text-color);
}

[data-pnotify].material-elem.pnotify-shadow {
  box-shadow: 0 6px 24px #0003;
}

[data-pnotify] .material-container {
  background-color: var(--material-background-color);
  color: var(--material-text-color);
  border: none;
  padding: 24px;
}

[data-pnotify] .material-title {
  letter-spacing: .15px;
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

[data-pnotify] .material-title:last-child {
  margin-bottom: 0;
}

[data-pnotify] .material-text {
  letter-spacing: .5px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

[data-pnotify].pnotify-with-icon .material-content {
  margin-left: 32px;
}

[dir="rtl"] [data-pnotify].pnotify-with-icon .material-content {
  margin-left: 0;
  margin-right: 32px;
}

[data-pnotify] .material-closer, [data-pnotify] .material-icon, [data-pnotify] .material-icon-closer:after, [data-pnotify] .material-icon-sticker:after, [data-pnotify] .material-icon > span:after, [data-pnotify] .material-sticker {
  width: 20px;
  height: 20px;
  font-family: Material Icons;
  font-size: 20px;
  line-height: 24px;
  position: relative;
}

[data-pnotify] .material-action-bar {
  margin-top: 20px;
  margin-bottom: -16px;
  margin-right: -16px;
}

[dir="rtl"] [data-pnotify] .material-action-bar {
  margin-left: -16px;
  margin-right: 0;
}

[data-pnotify] .material-icon-notice:after {
  content: "priority_high";
}

[data-pnotify] .material-icon-info:after {
  content: "info";
}

[data-pnotify] .material-icon-success:after {
  content: "done";
}

[data-pnotify] .material-icon-error:after {
  content: "error";
}

[data-pnotify] .material-icon-closer:after {
  content: "close";
}

[data-pnotify] .material-icon-unstuck:after {
  content: "pause";
}

[data-pnotify] .material-icon-stuck:after {
  content: "play_arrow";
}

[data-pnotify] .material-input {
  color: inherit;
  border-style: none none solid;
  border-top-width: medium;
  border-top-color: currentColor;
  border-left-width: medium;
  border-left-color: currentColor;
  border-right-width: medium;
  border-right-color: currentColor;
  border-bottom-color: inherit;
  background-color: #0000;
  border-bottom-width: 1px;
  border-radius: 0;
  width: 100%;
  margin-bottom: 8px;
  padding: 15px 0 8px;
  display: block;
}

[data-pnotify] .material-input:focus {
  border-bottom-color: var(--input-focus-underline-color);
  border-bottom-width: 2px;
  outline: none;
}

[data-pnotify] .material-btn {
  color: inherit;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-color: #0000;
  border-width: 0;
  border-radius: 2px;
  outline: none;
  min-width: 64px;
  height: 36px;
  margin: 6px;
  padding: 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  transition: background-color .3s;
  position: relative;
  overflow: hidden;
}

[data-pnotify] .material-btn.material-btn-primary {
  color: var(--primary-button-text-color);
}

[data-pnotify] .material-btn:focus, [data-pnotify] .material-btn:hover {
  color: inherit;
  background-color: #0000001f;
}

[data-pnotify] .material-btn.material-btn-primary:focus, [data-pnotify] .material-btn.material-btn-primary:hover {
  color: var(--primary-button-text-focus-color);
}

[data-pnotify] .material-btn:before {
  content: "";
  background-color: #9996;
  border-radius: 100%;
  width: 0;
  padding-top: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-pnotify] .material-btn:active:before {
  width: 120%;
  padding-top: 120%;
  transition: width .2s ease-out, padding-top .2s ease-out;
}

[data-pnotify] .material-countdown {
  background-color: var(--material-background-color);
}

[data-pnotify] .material-countdown-bar {
  background-color: var(--material-text-color);
}

[data-pnotify] .material-paginate {
  flex-direction: column;
  display: inline-flex;
}

[data-pnotify] .material-paginate-buttons {
  margin-right: 6px;
}

[data-pnotify] .material-paginate-btn {
  border: 0;
  margin: 0 0 0 6px;
  padding: 0;
}

[data-pnotify] .material-paginate-btn, [data-pnotify] .material-paginate-btn:after {
  width: 20px;
  height: 20px;
  font-family: Material Icons;
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

[data-pnotify] .material-paginate-btn:disabled:after, [data-pnotify] .material-paginate-btn[aria-disabled="true"]:after {
  opacity: .5;
}

[data-pnotify] .material-paginate-previous:after {
  content: "arrow_back";
}

[data-pnotify] .material-paginate-next:after {
  content: "arrow_forward";
}

[data-pnotify] .material-paginate-count {
  letter-spacing: .25px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

body > .pnotify.pnotify-positioned {
  z-index: 100040;
  position: fixed;
}

body > .pnotify.pnotify-modal {
  z-index: 100042;
}

.pnotify {
  opacity: 0;
  height: auto;
  transition: opacity .1s linear;
  display: none;
}

.pnotify.pnotify-positioned {
  z-index: 1;
  position: absolute;
}

.pnotify.pnotify-modal {
  z-index: 3;
}

.pnotify.pnotify-in {
  opacity: 1;
  display: block;
}

.pnotify.pnotify-initial {
  display: block;
}

.pnotify-hidden {
  visibility: hidden;
}

.pnotify.pnotify-move {
  transition: left .4s, top .4s, right .4s, bottom .4s;
}

.pnotify.pnotify-fade-slow {
  opacity: 0;
  transition: opacity .4s linear;
}

.pnotify.pnotify-fade-slow.pnotify.pnotify-move {
  transition: opacity .4s linear, left .4s, top .4s, right .4s, bottom .4s;
}

.pnotify.pnotify-fade-normal {
  opacity: 0;
  transition: opacity .25s linear;
}

.pnotify.pnotify-fade-normal.pnotify.pnotify-move {
  transition: opacity .25s linear, left .4s, top .4s, right .4s, bottom .4s;
}

.pnotify.pnotify-fade-fast {
  opacity: 0;
  transition: opacity .1s linear;
}

.pnotify.pnotify-fade-fast.pnotify.pnotify-move {
  transition: opacity .1s linear, left .4s, top .4s, right .4s, bottom .4s;
}

.pnotify.pnotify-masking {
  display: block;
  -webkit-mask-image: linear-gradient(#000c, #0000 30px, #0000);
  mask-image: linear-gradient(#000c, #0000 30px, #0000);
}

.pnotify.pnotify-masking.pnotify-stack-up {
  -webkit-mask-image: linear-gradient(0deg, #000c, #0000 30px, #0000);
  mask-image: linear-gradient(0deg, #000c, #0000 30px, #0000);
}

.pnotify.pnotify-masking.pnotify-stack-left {
  -webkit-mask-image: linear-gradient(270deg, #000c, #0000 30px, #0000);
  mask-image: linear-gradient(270deg, #000c, #0000 30px, #0000);
}

.pnotify.pnotify-masking.pnotify-stack-right {
  -webkit-mask-image: linear-gradient(90deg, #000c, #0000 30px, #0000);
  mask-image: linear-gradient(90deg, #000c, #0000 30px, #0000);
}

.pnotify.pnotify-fade-in, .pnotify.pnotify-masking-in {
  opacity: 1;
}

.pnotify .pnotify-shadow {
  box-shadow: 0 6px 28px #0000001a;
}

.pnotify-container {
  background-position: 0 0;
  height: 100%;
  margin: 0;
  padding: .8em;
  position: relative;
}

.pnotify-container:after {
  content: " ";
  visibility: hidden;
  clear: both;
  height: 0;
  display: block;
}

.pnotify-closer, .pnotify-sticker {
  float: right;
  cursor: pointer;
  margin-left: .5em;
}

[dir="rtl"] .pnotify-closer, [dir="rtl"] .pnotify-sticker {
  float: left;
  margin-left: 0;
  margin-right: .5em;
}

.pnotify-title {
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: .4em;
  display: block;
}

.pnotify-text-with-max-height {
  overscroll-behavior: contain;
  padding-bottom: .03em;
  overflow-y: auto;
}

.pnotify.pnotify-with-icon .pnotify-content {
  margin-left: 24px;
}

[dir="rtl"] .pnotify.pnotify-with-icon .pnotify-content {
  margin-left: 0;
  margin-right: 24px;
}

.pnotify-pre-line {
  white-space: pre-line;
}

.pnotify-icon, .pnotify-icon span {
  float: left;
  display: block;
}

[dir="rtl"] .pnotify-icon, [dir="rtl"] .pnotify-icon span {
  float: right;
}

.pnotify-modal-overlay {
  z-index: 2;
  opacity: 0;
  background-color: #0009;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 0;
  transition: opacity .25s linear;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.pnotify-modal-overlay-up {
  align-items: flex-start;
}

.pnotify-modal-overlay-left {
  justify-content: flex-start;
  align-items: center;
}

.pnotify-modal-overlay-right {
  justify-content: flex-end;
  align-items: center;
}

.pnotify-modal-overlay.pnotify-modal-overlay-in {
  opacity: 1;
}

.pnotify-modal-overlay-closes:after {
  content: "×";
  color: #fff;
  text-shadow: 0 0 .4rem #fff;
  font-family: Arial;
  font-size: 3rem;
}

body > .pnotify-modal-overlay {
  z-index: 100041;
  position: fixed;
}

pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #abb2bf;
  background: #282c34;
}

.hljs-keyword, .hljs-operator, .hljs-pattern-match {
  color: #f92672;
}

.hljs-pattern-match .hljs-constructor, .hljs-function {
  color: #61aeee;
}

.hljs-function .hljs-params {
  color: #a6e22e;
}

.hljs-function .hljs-params .hljs-typing {
  color: #fd971f;
}

.hljs-module-access .hljs-module {
  color: #7e57c2;
}

.hljs-constructor {
  color: #e2b93d;
}

.hljs-constructor .hljs-string {
  color: #9ccc65;
}

.hljs-comment, .hljs-quote {
  color: #b18eb1;
  font-style: italic;
}

.hljs-doctag, .hljs-formula {
  color: #c678dd;
}

.hljs-section, .hljs-name, .hljs-selector-tag, .hljs-deletion, .hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-string, .hljs-regexp, .hljs-addition, .hljs-attribute, .hljs-meta .hljs-string {
  color: #98c379;
}

.hljs-built_in, .hljs-title.class_, .hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr, .hljs-variable, .hljs-template-variable, .hljs-type, .hljs-selector-class, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-number {
  color: #d19a66;
}

.hljs-symbol, .hljs-bullet, .hljs-link, .hljs-meta, .hljs-selector-id, .hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

body {
  background: #000;
  margin: 0;
  padding: 20px 50px;
}

textarea {
  color: #fff;
  resize: none;
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-family: Fira Code, Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 13px;
}

#linenos {
  color: #7d7d7d;
  text-align: right;
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
  font-family: Fira Code, Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 15px;
  position: absolute;
  top: 20px;
  left: 0;
}

#box {
  overflow: inherit;
  border: 0;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

#box code, pre {
  padding: 0;
  font-family: Fira Code, Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 15px;
  background: none !important;
}

#key {
  z-index: 1000;
  width: 200px;
  position: fixed;
  top: 0;
  right: 0;
}

#box1 {
  text-align: center;
  background: #141512;
  padding: 5px;
}

#box2 {
  background: #141512;
  place-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 0;
  display: flex;
}

#box1 a.logo, #box1 a.logo:visited {
  width: 126px;
  height: 42px;
  display: inline-block;
}

#box1 a.logo:hover {
  background-position: 0 bottom;
}

#box2 .function {
  background: url("function-icons.9d1c0bb3.png");
  width: 32px;
  height: 37px;
  display: inline-block;
  position: relative;
}

#box2 .link embed {
  vertical-align: bottom;
}

#box2 .function.enabled:hover {
  cursor: hand;
  cursor: pointer;
}

#box3 {
  -webkit-user-select: none;
  user-select: none;
  background: #141512;
  padding: 10px 15px;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
  line-height: 14px;
}

#box3 .label {
  color: #fff;
  font-weight: bold;
}

#box3 .shortcut {
  color: #c4dce3;
  font-weight: normal;
}

#box2 .function.save {
  background-position: -5px 0;
}

#box2 .function.enabled.save {
  background-position: -5px;
}

#box2 .function.enabled.save:hover {
  background-position: -5px bottom;
}

#box2 .function.new {
  background-position: -42px 0;
}

#box2 .function.enabled.new {
  background-position: -42px;
}

#box2 .function.enabled.new:hover {
  background-position: -42px bottom;
}

#box2 .function.duplicate {
  background-position: -79px 0;
}

#box2 .function.enabled.duplicate {
  background-position: -79px;
}

#box2 .function.enabled.duplicate:hover {
  background-position: -79px bottom;
}

#box2 .function.raw {
  background-position: -116px 0;
}

#box2 .function.enabled.raw {
  background-position: -116px;
}

#box2 .function.enabled.raw:hover {
  background-position: -116px bottom;
}

#box2 .button-picture {
  font-size: inherit;
  border-width: 0;
}

/*# sourceMappingURL=index.3d52c437.css.map */
