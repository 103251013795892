$fonts:
	'Fira Code',
	Consolas,
	Andale Mono WT,
	Andale Mono,
	Lucida Console,
	Lucida Sans Typewriter,
	DejaVu Sans Mono,
	Bitstream Vera Sans Mono,
	Liberation Mono,
	Nimbus Mono L,
	Monaco,
	Courier New,
	Courier,
	monospace,
	-apple-system,
	BlinkMacSystemFont,
	'Segoe UI',
	Roboto,
	Oxygen,
	Ubuntu,
	Cantarell,
	'Open Sans',
	'Helvetica Neue',
	sans-serif;

$darkBlack: #141512;

body {
	background: #000000;
	padding: 20px 50px;
	margin: 0px;
}

/* textarea */

textarea {
	background: transparent;
	border: 0px;
	color: #fff;
	padding: 0px;
	width: 100%;
	height: 100%;
	font-family: $fonts;
	outline: none;
	resize: none;
	font-size: 13px;
	margin-top: 0;
	margin-bottom: 0;
}

/* the line numbers */

#linenos {
	color: #7d7d7d;
	position: absolute;
	top: 20px;
	left: 0px;
	width: 30px; /* 30 to get 20 away from box */
	font-size: 15px;
	font-family: $fonts;
	text-align: right;
	user-select: none;
}

/* code box when locked */

#box {
	padding: 0px;
	margin: 0px;
	width: 100%;
	border: 0px;
	outline: none;
	font-size: 13px;
	overflow: inherit;
}

#box code,
pre {
	padding: 0px;
	background: transparent !important; /* don't hide hastebox */
	font-size: 15px;
	font-family: $fonts;
}

/* key */

#key {
	position: fixed;
	top: 0px;
	right: 0px;
	z-index: 1000;
	width: 200px;
}

#box1 {
	padding: 5px;
	text-align: center;
	background: $darkBlack;
}

#box2 {
	background: $darkBlack;
	font-size: 0px;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
}

#box1 a.logo,
#box1 a.logo:visited {
	display: inline-block;
	width: 126px;
	height: 42px;
}

#box1 a.logo:hover {
	background-position: 0 bottom;
}

#box2 .function {
	background: url(../images/function-icons.png);
	width: 32px;
	height: 37px;
	display: inline-block;
	position: relative;
}

#box2 .link embed {
	vertical-align: bottom; /* fix for zeroClipboard style */
}

#box2 .function.enabled:hover {
	cursor: hand;
	cursor: pointer;
}

#box3 {
	background: $darkBlack;
	font-family: Helvetica, sans-serif;
	font-size: 12px;
	line-height: 14px;
	padding: 10px 15px;
	user-select: none;
}

#box3 .label {
	color: #fff;
	font-weight: bold;
}

#box3 .shortcut {
	color: #c4dce3;
	font-weight: normal;
}

#box2 .function.save {
	background-position: -5px top;
}
#box2 .function.enabled.save {
	background-position: -5px center;
}
#box2 .function.enabled.save:hover {
	background-position: -5px bottom;
}

#box2 .function.new {
	background-position: -42px top;
}
#box2 .function.enabled.new {
	background-position: -42px center;
}
#box2 .function.enabled.new:hover {
	background-position: -42px bottom;
}

#box2 .function.duplicate {
	background-position: -79px top;
}
#box2 .function.enabled.duplicate {
	background-position: -79px center;
}
#box2 .function.enabled.duplicate:hover {
	background-position: -79px bottom;
}

#box2 .function.raw {
	background-position: -116px top;
}
#box2 .function.enabled.raw {
	background-position: -116px center;
}
#box2 .function.enabled.raw:hover {
	background-position: -116px bottom;
}

#box2 .button-picture {
	border-width: 0;
	font-size: inherit;
}
